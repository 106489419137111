import pricelistService from "@/services/SalesAdministrative/pricelist.service";

export default {
  pricelistTemplates: {
    name: "pricelist_templates",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Title",
        type: "string",
        required: true,
        allowEmpty: false,
        readonly: false
      },
      {
        name: "Template URL",
        type: "string",
        required: true,
        allowEmpty: false,
        readonly: false
      }
    ],
    services: {
      fetchData: pricelistService.fetchPricelistTemplates,
      fetchRecord: pricelistService.fetchPricelistTemplate,
      saveRecord: pricelistService.savePricelistTemplate,
      deleteRecord: pricelistService.deletePricelistTemplate
    },
    actions: {
      Edit: {
        validator: () => true
      },
      Create: {
        validator: () => true
      },
      Delete: {
        validator: () => true
      }
    }
  }
};
