import {api} from '@/shared/api'
import moment from 'moment'

const distributor = {
  fetchDistributorMarkets: async payload => {
    let res = await api.get(`distributors/${payload.account_id}/markets`)

    return res
  },

  fetchDistributorProductPrices: async payload => {


    payload = {
      ...payload, 
      distributor_id: payload.parentId,
      market_id: undefined,
      effective_date: payload.distributor_product_prices_valid_from
    }

    console.log('fetchDistributorProductPrices.payload', payload)

    const res = await api.post('meta-models/distributor-product-prices/search', payload)

    console.log('fetchDistributorProductPrices.response', res)
    return res
  },
  saveDistributorProductPrices: async payload => {
    console.log('saveDistributorProductPrices.payload', payload)


    //GUID retured from the server for virtual records, they should be created if edited
    if (typeof payload.ID === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(payload.ID)) {
      payload.ID = undefined
    }
    //Create new records if Valid From greater than Price List Date
    console.log('saveDistributorProductPrices[Valid From > Price List Date]',new Date(payload["Valid From"]) > new Date(payload["Price List Date"]))
    if (new Date(payload["Valid From"]) > new Date(payload["Price List Date"])) {
      payload.ID = undefined
    }
  

    const method = payload.ID ? 'put' : 'post'
    const res = await api[method]('meta-models/distributor-product-prices', payload)

    return res
  },
  deleteDistributorProductPrices: async ID => {
    console.log('deleteDistributorProductPrices.ID', ID)
    return api.delete(`meta-models/distributor-product-prices/${ID}`, {id: ID})
  }
}

export default distributor
