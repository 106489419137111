import availableProducts from "./available-products.model";
import availableProductsTotals from "./available-products-totals.model";
import batchRemains from "./batch-remains.model";
import incomingOrders from "./incoming-orders.model";
import incomingOrderItems from "./incoming-order-items.model";
import productNeed from "./product-need.model";
import productNeedAccount from "./product-need-account.model";
import pickLists from "./pick-lists.model";
import pickListItemBottlings from "./pick-list-item-bottlings.model";
import bottlingsSearch from "./bottlings-search.model";
import invoices from "./invoices.model";
import invoiceItems from "./invoice-items.model";
import invoiceProductsTotals from "./invoice-products-totals.model";
import salesDetailsReport from "./SalesDetailsReport/sales-details-report.model.js";

//TODO - check model names uniquness!

export default {
  ...availableProducts,
  ...availableProductsTotals,
  ...incomingOrders,
  ...incomingOrderItems,
  ...productNeed,
  ...productNeedAccount,
  ...pickLists,
  ...pickListItemBottlings,
  ...bottlingsSearch,
  ...invoices,
  ...invoiceItems,
  ...invoiceProductsTotals,
  ...salesDetailsReport,
  ...batchRemains
};
