import productServices from '@/services/SalesAdministrative/products.service';
import dictionaryServices from '@/services/dictionary.service';

export default {
  products: {
    name: 'products',
    entities: [
      {
        name: 'ID',
        type: 'string',
        readonly: true,
      },
      {
        name: 'Product Name',
        type: 'string',
        readonly: false,
        required: true,
      },
      {
        name: 'Product Name Full',
        type: 'string',
        readonly: true,
        required: false,
      },
      {
        name: 'Is Product Group',
        type: 'boolean',
        readonly: false,
      },
      {
        name: 'Category',
        type: 'dropdown',
        readonly: false,
        optionsService: productServices.fetchProductsCategories,
        required: true,
      },
      {
        name: 'Description',
        type: 'string',
        readonly: false,
        allowEmpty: false,
        required: true,
      },
      {
        name: 'Notes',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Base/Finished Product',
        type: 'dropdown',
        readonly: false,
        optionsService: productServices.fetchFinishedProducts,
      },
      {
        name: 'Bottle Size',
        type: 'dropdown',
        readonly: false,
        optionsService: productServices.fetchBottleSizes,
      },
      {
        name: 'Bottle Size JSON',
        type: 'dropdown',
        readonly: false,
        optionsService: productServices.fetchBottleSizes,
      },
      {
        name: 'ABV',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Product Type',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Bottle Height',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Bottle Width',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Bottle Weight',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Case Size',
        type: 'dropdown',
        readonly: false,
        optionsService: dictionaryServices.fetchCaseSizes,
      },
      {
        name: 'Cases per Layer',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Layers per Pallet',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Case Dimensions',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Case Height',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Case Weight',
        type: 'string',
        readonly: false,
      },

      {
        name: 'Unit Price',
        type: 'string',
        readonly: false,
      },
      {
        name: 'GS1 UPC',
        type: 'string',
        readonly: false,
      },
      {
        name: 'SCC Code',
        type: 'string',
        readonly: false,
      },
      {
        name: 'NABCA Code',
        type: 'string',
        readonly: false,
      },
      {
        name: 'TTB ID',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Schedule B',
        type: 'string',
        readonly: false,
      },
      {
        name: 'CN Codes EU',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Front Label',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Back Label',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Product Code',
        type: 'string',
        readonly: false,
      },
      {
        name: 'Serial No', 
        type: 'string', 
        readonly: false 
    },
    {
        name: 'Is Product Group', 
        type: 'boolean', 
        readonly: false 
    },
    {
        name: 'Group Products', 
        type: 'dropdown', 
        readonly: false ,
        optionsService: () => [],

    },
    {
        name: 'Category JSON', 
        type: 'string', 
        readonly: false 
    },
    {
        name: 'Finished Product JSON', 
        type: 'string', 
        readonly: false 
    },
    {
        name: 'Case Size JSON', 
        type: 'string', 
        readonly: false 
    },
    {
        name: 'Cases per Pallet', 
        type: 'string', 
        readonly: false 
    },
   
    {
        name: 'Weight', 
        type: 'string', 
        readonly: false 
    },
    {
        name: 'Sales Start Date', 
        type: 'datetime', 
        readonly: false 
    },
    {
        name: 'Sales End Date', 
        type: 'datetime', 
        readonly: false 
    },
    {
        name: 'Start Date', 
        type: 'datetime', 
        readonly: false 
    },
    {
        name: 'ReOrderLevel', 
        type: 'string', 
        readonly: false 
    },
    {
        name: 'Deleted', 
        type: 'string', 
        readonly: false 
    },
    {
        name: 'ABV Number', 
        type: 'string', 
        readonly: false 
    },

      {
        name: 'Discontinued',
        type: 'dropdown',
        readonly: false,
        optionsService: () => [
          { id: '1', label: 'Yes' },
          { id: '0', label: 'No' },
        ],
      },
    ],
    services: {
      fetchRecord: productServices.fetchProduct,
      fetchData: productServices.fetchProducts,
      saveRecord: productServices.saveProducts,
      deleteRecord: productServices.deleteProduct,
    },
    detailsRouterName: 'Product submission',
    actions: {
      Create: {
        validator: () => true,
      },
      View: {
        validator: () => true,
      },
      Edit: {
        validator: () => true,
      },
      Delete: {
        validator: () => true,
      },
    },
  },
};
