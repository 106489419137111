import warehouseServices from "@/services/warehouse.service";
import dictionaryServices from "@/services/dictionary.service";

export default {
  invoices: {
    name: "warehouse_invoices",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Warehouse",
        type: "dropdown",
        readonly: false,
        allowEmpty: false,
        multiple: false,
        required: true,
        optionsService: dictionaryServices.fetchWarehouses
      },
      {
        name: "PO Date",
        type: "date"
      },
      {
        name: "Shipment Date",
        type: "date"
      },
      {
        name: "Invoice Date",
        type: "date"
      },
      {
        name: "PO Number",
        type: "string",
        readonly: false,
        required: true
      },
      {
        name: "Distributor",
        type: "dropdown",
        required: true,
        optionsService: dictionaryServices.fetchDistributors
      },
      {
        name: "Currency",
        type: "dropdown",
        required: true,
        optionsService: dictionaryServices.fetchInventoryCurrencies
      },
      {
        name: "Status",
        type: "string",
        required: false,
        readonly: true
      },
      {
        name: "Discount",
        type: "number",
        readonly: false
      },
      {
        name: "Total",
        type: "number",
        readonly: true
      },
      {
        name: "Bottles",
        type: "number",
        readonly: true
      },

      {
        name: "Exchange Rate",
        type: "number",
        readonly: false
      },
      {
        name: "Proof Gallons",
        type: "number",
        readonly: true
      },
      {
        name: "Wine Gallons",
        type: "number",
        readonly: true
      },
      {
        name: "Status",
        type: "string",
        readonly: true
      },
      {
        name: "Notes",
        type: "string",
        isTextArea: true
      },
      {
        name: "Created By",
        type: "string",
        readonly: true
      },
      {
        name: "Modified By",
        type: "string",
        readonly: true
      },
      {
        name: "Created",
        type: "string",
        readonly: true
      },
      {
        name: "Modified",
        type: "string",
        readonly: true
      },
      {
        name: "QB Number",
        type: "string",
        readonly: false
      },
      {
        name: "QB Date",
        type: "date",
        readonly: false
      },
      {
        name: "QB Amount",
        type: "number",
        readonly: false
      },
      {
        name: "Check Status",
        type: "dropdown",
        readonly: true,
        optionsService: warehouseServices.fetchInvoiceCheckStatuses
      },
      {
        name: "Terms",
        type: "string",
        readonly: true
      },
      {
        name: "Pay Status",
        type: "dropdown",
        readonly: true,
        optionsService: warehouseServices.fetchInvoicePayStatuses
      }
    ],
    services: {
      fetchData: warehouseServices.fetchInvoices,
      fetchRecord: warehouseServices.fetchInvoice,
      saveRecord: warehouseServices.saveInvoice,
      deleteRecord: warehouseServices.deleteInvoice
    },
    tabularRouterName: "WH Invoices",
    detailsRouterName: "WH Invoice submission",
    //actions: ["View", "Edit"]
    actions: {
      View: {
        validator: () => true
      },
      Edit: {
        validator: () => true
      }
    }
  }
};
