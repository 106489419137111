import { api } from "@/shared/api";

export default {

fetchBatchRemains: async (payload) => {

  console.log('fetchBatchRemains:',payload)
    return api.post('meta-models/warehouse-batch-remains/search', payload)
  }

}