import { api } from '@/shared/api'

export default {
  
  fetchPricelistTemplates: async () => {
    return await api.get('meta-models/pricelist-templates');
  },
  
  fetchEmailTemplates: async () => {
    const response = await api.get('products/pricelist-email-templates');
    console.log('Email templates from API:', response);
    return response;
  },
  
  fetchPricelistTemplate: async (id) => {
    return await api.get(`meta-models/pricelist-templates/${id}`);
  },

  savePricelistTemplate: async (payload) => {
    const method = payload.ID ? 'put' : 'post'
    return await api[method](`meta-models/pricelist-templates`, payload);
  },

  deletePricelistTemplate: async (ID) => {
    return api.delete(`meta-models/pricelist-templates/${ID}`, {id: ID})
  }
};
