import warehouseServices from '@/services/warehouse.service'
import dictionaryServices from '@/services/dictionary.service'

export default {
  pickLists: {
    name: 'warehouse_pick_lists',
    entities: [
      {
        name: 'Incoming Order',
        type: 'dropdown',
        allowEmpty: false,
        multiple: false,
        required: true,
        readonly: false,
        optionsService: dictionaryServices.fetchIncomingOrdersWithoutPicklists
      },

      {
        name: 'Warehouse',
        type: 'dropdown',
        readonly: false,
        allowEmpty: false,
        multiple: false,
        required: true,
        optionsService: dictionaryServices.fetchWarehouses
      },
      {
        name: 'Picklist Date',
        type: 'date'
      },
      {
        name: 'PO Date',
        type: 'date',
        readonly: false
      },
      {
        name: 'PO Number',
        type: 'string',
        readonly: false
      },

      { name: 'PO Confirmed', readonly: true },
      {
        name: 'Distributor',
        type: 'dropdown',
        readonly: false,
        optionsService: dictionaryServices.fetchDistributors
      },
      {
        name: 'Currency',
        type: 'dropdown',
        readonly: false,
        optionsService: dictionaryServices.fetchInventoryCurrencies
      },
      {
        name: 'Discount',
        type: 'number',
        readonly: false
      },
      {
        name: 'Bottles',
        type: 'number',
        readonly: false
      },

      {
        name: 'Total',
        type: 'number',
        readonly: true
      },
      {
        name: 'Invoice Number',
        type: 'string',
        readonly: true
      },
      {
        name: 'Status',
        type: 'string',
        readonly: true
      },
      {
        name: 'Notes',
        type: 'string',
        isTextArea: true
      },
      {
        name: 'Created By',
        type: 'string',
        readonly: true
      },
      {
        name: 'Modified By',
        type: 'string',
        readonly: true
      },
      {
        name: 'Created',
        type: 'string',
        readonly: true
      },
      {
        name: 'Modified',
        type: 'string',
        readonly: true
      }
    ],
    services: {
      fetchData: warehouseServices.fetchPickLists,
      fetchRecord: warehouseServices.fetchPickList,
      saveRecord: warehouseServices.savePickList,
      deleteRecord: warehouseServices.deletePickList
    },
    tabularRouterName: 'Pick lists',
    detailsRouterName: 'Pick list submission',
    //actions: ["View", "Edit", "Delete"]
    actions: {
      View: {
        validator: () => true
      },
      Edit: {
        validator: row => !(row['Invoice Number'] > 0)
      },
      Delete: {
        validator: row => !(row['Invoice Number'] > 0)
      }
    }
  }
}
