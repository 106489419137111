import dictionaryServices from '@/services/dictionary.service'
import batchRemainsService from '@/services/Warehouse/batch-remains.service'
import { readonly } from 'vue'


export default {
  batchRemains: {
    name: 'warehouse_batch_remains',
    entities: [
      {
        name: 'ID',
        type: 'number',
        readonly: true
      },
      
      {
        name: 'Finished Product',
        type: 'dropdown',
        readonly: true,
        optionsService: dictionaryServices.fetchFinishedProducts
      },
      
      {
        name: 'Product Name Full',
        type: 'dropdown',
        optionsService: dictionaryServices.fetchProducts,
        readonly: true
      },
      {
        name: 'Batch Number',
        type: 'string',
        readonly: true
      },
      {
        name: 'Bottling Date',
        type: 'date',
        readonly: true
      },
      {
        name: 'Last Sold Date',
        type: 'date',
        readonly: true
      },
      {
        name: 'Bottled',
        type: 'number',
        readonly: true
      },
      {
        name: 'Sold',
        type: 'number',
        readonly: true
      },
      {
        name: 'In Stock',
        type: 'number',
        readonly: true
      },
      {
        name: 'Difference',
        type: 'number',
        readonly: true
      },
      {
        name: 'Variance',
        type: 'number',
        readonly: true
      },
      {
        name: 'Duplicated Batch',
        type: 'number',
        readonly: true
      }
    ],
    services: {
      fetchData: batchRemainsService.fetchBatchRemains,
      
    },
    detailsRouterName: null,
    actions: {
      Create: { validator: () => true },
      Edit: { validator: () => true },
      Delete: { validator: () => true },
      View: { validator: () => true }
    }
  }
}
